import { observer } from '@vklink/libs-state';

import { Button, Stack, Typography } from '@mui/material';
import { useAuthStore } from '@vklink/libs-auth';

import background from 'assets/welcome.png';

const WelcomePage = observer(() => {
  const { signinAsync } = useAuthStore();

  return (
    <Stack
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h5"
        component="h1"
        textAlign="center"
        color={(theme) => theme.palette.common.white}
      >
        Welcome to VGS Shop Portal
      </Typography>

      <Button
        onClick={() => signinAsync()}
        sx={{ mt: 2, height: 36 }}
        variant="contained"
        size="large"
      >
        Sign in
      </Button>
    </Stack>
  );
});

export default WelcomePage;
