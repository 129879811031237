import { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Badge,
} from '@mui/material';
import { Notifications } from '@mui/icons-material';
import avatar from 'assets/avatar.jpg';
import English from 'assets/gb.svg';
import VietNam from 'assets/vn.svg';
import LogoutMenuItem from './LogoutMenuItem';
import logoVKLink from 'assets/logo-vgsshop.svg';

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState('en');

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleChangeLanguage = () => {
    if (language === 'en') {
      setLanguage('vi');
    } else {
      setLanguage('en');
    }
  };

  const settings = ['Profile', 'Settings'];

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: (theme) => theme.palette.common.white,
      }}
    >
      <Toolbar>
        <Box
          component="img"
          sx={{
            height: 50,
          }}
          alt="Logo VKLink."
          src={logoVKLink}
        />
        <Box flexGrow={1} />
        <Box
          component="img"
          sx={{
            height: 20,
            width: 40,
          }}
          alt="The house from the offer."
          src={language === 'en' ? English : VietNam}
          onClick={handleChangeLanguage}
        />
        <Badge badgeContent={4} color="warning" sx={{ ml: 2 }}>
          <Notifications color="action" />
        </Badge>
        <Box sx={{ flexGrow: 0, ml: 4 }} color="GrayText">
          <Tooltip title="Open Settings">
            <Typography component="div" onClick={handleOpenNavMenu} sx={{ px: 1 }}>
              <IconButton sx={{ p: 0 }}>
                <Avatar alt="Administrator" src={avatar} />
              </IconButton>
              <Typography component="span" sx={{ ml: 2 }}>
                Hi, Julia
              </Typography>
            </Typography>
          </Tooltip>
          <Menu
            sx={{ mt: (theme) => `${theme.mixins.toolbar.minHeight}px` }}
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseNavMenu}>
                {setting}
              </MenuItem>
            ))}
            <LogoutMenuItem />
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
